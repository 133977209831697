// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~toastr/toastr';

@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';

@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~bootstrap-fileinput/scss/fileinput.scss';


.file-caption .file-caption-name {
    width: 50%!important;
    margin: 0;
    padding: 0;
    box-shadow: none;
    border: none;
    background: none;
    outline: none;
}

.fileinput-remove-button {
    color: #fff!important;
}
